import { getCountry } from '/imports/checkout/api/utils';

export const getCurrencyFormat = (price) => {
  const country = getCountry();
  switch (country) {
    case 'FR':
    case 'ES':
    case 'BE':
    case 'LU':
    case 'CH':
      return formatCurrency(price, 'EUR');
    case 'CL':
      return formatCurrency(price, 'CLP');
    default:
      return formatCurrency(price, 'USD');
  }
};
export const getCountryCode = () => {
  const country = getCountry();
  switch (country) {
    case 'FR':
    case 'ES':
    case 'BE':
    case 'LU':
    case 'CH':
      return 'EUR';
    case 'CL':
      return 'CLP';
    default:
      return 'USD';
  }
};

const formatCurrency = (amount, currencyCode) => {
  const options = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (currencyCode === 'CLP') options.style = 'decimal';

  let formattedAmount = new Intl.NumberFormat(getLocale(currencyCode), options).format(amount);
  if (currencyCode === 'CLP') formattedAmount = 'CLP ' + formattedAmount;
  else {
    const symbol = getCurrencySymbol(currencyCode);
    formattedAmount = formattedAmount.replace(/\$\s?/, symbol).replace(/\s/g, '');
  }

  return formattedAmount;
};

const getLocale = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return 'en-US';
    case 'EUR':
      return 'fr-FR';
    case 'CLP':
      return 'es-CL';
    default:
      return 'en-US';
  }
};

const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'CLP':
      return 'CLP ';
    default:
      return '';
  }
};

const FRENCH_QUESTION_MAP = {
  'Quel(s) service(s) de conseil en gestion de carrière 1:1 vous intéresse(nt) ?': 'career_services_interest',
  'Combien d`années d`expérience avez-vous ?': 'experience_years',
  "Êtes-vous intéressé à recevoir de l'aide d'un professionnel ? Vous pouvez choisir parmi plusieurs options":
    'additional_info',
};

const SPANISH_QUESTION_MAP = {
  '¿Cuales de estos servicios de asesoría de carrera 1:1 te interesan? * *': 'career_services_interest',
  '¿Cuantos años de experiencia tienes?*': 'experience_years',
  '¿Te interesa recibir ayuda profesional? Puedes elegir las siguientes opciones: ': 'additional_info',
};

const ENGLISH_QUESTION = {
  'Which Wonsulting 1:1 career service(s) \nare you interested in?*': 'career_services_interest',
  'How many years of experience do you have?*': 'experience_years',
  "Is there any additional information you'd like to share with us about how your job search is going ?":
    'additional_info',
};

export const convertToKeyValue = (arr, locale) => {
  const result = {};
  let questionMappings = null;
  switch (locale) {
    case 'fr':
      questionMappings = FRENCH_QUESTION_MAP;
      break;
    case 'es':
      questionMappings = SPANISH_QUESTION_MAP;
      break;
    case 'en':
      questionMappings = ENGLISH_QUESTION;
      break;
  }
  arr.forEach((item) => {
    const shortKey = questionMappings[item.question];
    if (shortKey) {
      result[shortKey] = item.answer;
    }
  });
  return result;
};
export const getJobTitle = (resume) => {
  const experienceBlock = resume.blocks.find((g) => g.type === 'EMPLOYMENT');
  const items = experienceBlock && experienceBlock.items;
  const firstItems = items && items.length > 0 && items[0];
  const fieldtitle = firstItems && firstItems?.fields && firstItems.fields?.title;
  const title = fieldtitle ? fieldtitle : 'Professionnels';
  return title;
};

export const LOCALES_WITH_CAREER_EXP = ['es'];

//Mexico , Colombia , Chile , Spain, Uruguay , Argentina
export const COUNTRY_WITH_CAREER_EXP = ['MX', 'CO', 'CL', 'ES', 'UY', 'AR'];

export const LOCALES_WITH_CAREER_LEAD = ['fr'];

//France
export const COUNTRY_WITH_CAREER_LEAD = ['FR'];

export const isWithCareerABTest = (locale, country) => {
  return LOCALES_WITH_CAREER_EXP.includes(locale) && COUNTRY_WITH_CAREER_EXP.includes(country);
};

export const isWithCareerLead = (locale, country) => {
  return LOCALES_WITH_CAREER_LEAD.includes(locale) && COUNTRY_WITH_CAREER_LEAD.includes(country);
};

export const getVariantWeightByLocale = (locale) => {
  const variants = { without_first_step_flow: 50, control: 50 };
  if (locale === 'es') {
    variants.without_first_step_flow = 20;
    variants.control = 80;
  }
  return variants;
};
